import Alpine from 'alpinejs'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import {
  CART_UPDATED,
  NOTICE_ADD_TO_CART,
  NOTICE_UPDATE_QUANTITY,
} from '@/constants/cart-events'
import { updateItems } from '@/scripts/cart'
import {
  getLineItemAttributeByKey,
  isSubscriptionLineItem,
  getSubscriptionType,
} from '@/scripts/cart/utilities'
import type {
  AdvancedCartItem,
  CartAddonItem,
  CartPageLineItemScopeData,
  CartPageLineItemUpdateAction,
  CartPageLineItemUpdatePayload,
} from '@/types/pages/cart'
import type {
  CartItem,
  CartLineInput,
  Product,
  ProductVariant,
} from '@/types/shopify'
import { formatPrice } from '@/utilities/number'

document.addEventListener('alpine:init', () => {
  dayjs.extend(advancedFormat)
  Alpine.data('cartPopupLineItem', () => ({
    product: undefined! as Product,
    giftProduct: undefined! as Product,
    variantNode: undefined! as ProductVariant,
    variantTitle: undefined! as string,
    variantPrice: undefined! as string,
    variantCompareAtPrice: undefined! as string,
    giftVariantPrice: undefined! as string,
    inputQuantity: undefined! as number,
    isUpdating: false,
    lineItemImageSrc: undefined as string | undefined,
    giftLineItemImageSrc: undefined as string | undefined,
    addons: [] as CartAddonItem[],
    productFeatures: [] as string[],
    giftProductFeatures: [] as string[],
    isSubscriptionItem: false,
    subscriptionType: '',
    rawData: undefined! as AdvancedCartItem,
    giftRawData: undefined! as AdvancedCartItem,
    giftInputQuantity: undefined! as number,
    swellGift: false,

    groupLinesToBags(
      line: AdvancedCartItem,
      lines: AdvancedCartItem[],
      rawAddons: any,
      rawAddonGroups: any,
    ) {
      const deliveryZoneId = getLineItemAttributeByKey(
        line,
        '_delivery_zone_id',
      )
      if (!deliveryZoneId) {
        return
      }

      if (rawAddons) {
        const addonsData = JSON.parse(rawAddons)
        if (rawAddonGroups) {
          const addonGroups = JSON.parse(rawAddonGroups)
          line.addonGroups = addonGroups
          this.addonGroups = addonGroups
        } else {
          line.addonGroups = [
            {
              id: 'ungrouped',
              label: 'Addons',
              addonIds: addonsData.map((addon: any) => addon.id),
              validation: {
                max: 6,
              },
            },
          ]
          this.addonGroups = line.addonGroups
        }

        for (const row of addonsData) {
          const addonIndex = lines.findIndex((line) =>
            line.merchandise.id.endsWith(row.id),
          )
          if (addonIndex === -1) {
            console.error('Addon not found')
            continue
          }
          line.addons = line.addons || []
          const alreadyExist = line.addons.find(
            (addon) => addon.id === lines[addonIndex].id,
          )
          if (!alreadyExist) {
            line.addons.push({
              ...lines[addonIndex],
              _addonQuantity: row.quantity,
              ...(this.addonGroups && {
                _groupId: this.addonGroups.find((group: any) =>
                  group.addonIds.includes(row.id),
                )?.id,
              }),
            })
          }
          // categories.recognizedQuantity[addonIndex] -=
          //   quantity * Number(row.quantity)
        }
        this.addons = line.addons
      }

      // categories.recognizedQuantity[index] -= quantity
      // isSubscriptionItem
      //   ? categories.subscriptions.push(line)
      //   : categories.otps.push(line)
      // return categories

      // groups.recognizedQuantity.forEach((quantity: number, index: number) => {
      //   if (quantity === 0) return
      //   const line = JSON.parse(JSON.stringify(this.lines[index]))
      //   const isSubscriptionItem = isSubscriptionLineItem(line)
      //   line.quantity = quantity
      //   isSubscriptionItem
      //     ? groups.subscriptions.push(line)
      //     : groups.otps.push(line)
      // })

      // const hasSubscriptions = groups.subscriptions.length
    },

    initializeItem(giftItem: any, cart: any, giftProductDetails: any) {
      if (giftItem && giftProductDetails) {
        const parsedItem = JSON.parse(JSON.stringify(giftItem))
        const parsedProductDetails = JSON.parse(
          JSON.stringify(giftProductDetails),
        )
        const giftItemIndex = cart.lines.findIndex(
          (line: AdvancedCartItem) =>
            line.merchandise.id === parsedItem.merchandiseId,
        )
        const giftItemFromCart: CartPageLineItemScopeData['line'] =
          cart.lines[giftItemIndex]

        this.giftLine = giftItemFromCart

        const {
          merchandise: { id, product },
          quantity,
        } = giftItemFromCart as CartPageLineItemScopeData['line']

        this.giftProduct = {
          ...product,
          subtitle: parsedProductDetails.subtitle,
        }

        const variantNode = product.variants.edges.find(
          (edge) => edge.node.id === id,
        )?.node!
        this.giftVariantPrice = Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: variantNode.price.currencyCode,
          //@ts-ignore trailingZeroDisplay works on browser but not nodejs
          trailingZeroDisplay: 'stripIfInteger',
        }).format(Number(variantNode.price.amount))
        this.giftInputQuantity = quantity
        this.giftLineItemImageSrc = this.getLineItemImage(
          this.giftProduct?.variants?.edges?.find(
            (edge: any) => edge.node.id === id,
          ).node?.image?.url || this.giftProduct.featuredImage.url,
        )

        this.giftRawData = giftItemFromCart
        return
      }
      this.giftProduct = undefined
    },

    eventHandlers: {
      [`@${NOTICE_ADD_TO_CART}.window`]() {
        const {
          cartPopupItem,
          cart,
          rawAddons,
          addonGroups,
          programStartDateString,
          product: productDetails,
          giftItem,
          giftProductDetails,
          isSwellGift = false,
        } = this.$event.detail

        // TODO: Consider taking just the last added item from the cart once Storefont API upgraded to 2023-10 version
        const currentItemFromCart = cart.lines.find(
          (line: AdvancedCartItem) =>
            line.merchandise.id.match(cartPopupItem?.id) &&
            (programStartDateString
              ? programStartDateString ===
                line.attributes.find((attr) => attr.key === '_start_date')
                  ?.value
              : true),
        )

        this.isSwellGift = isSwellGift
        this.redemptionDiscount = 0
        this.isPartialRedemption = false
        this.initializeItem(giftItem, cart, giftProductDetails)

        if (!cartPopupItem || !currentItemFromCart) {
          this.line = null
          this.product = null
          return
        }

        this.line = currentItemFromCart
        this.isPartialRedemption = !!this.line.attributes.find(
          (attr: any) =>
            attr.key === '_swell_partial_redemption' && attr.value !== 'null',
        )
        this.redemptionDiscount = this.line.attributes.find(
          (attr: any) =>
            attr.key === '_swell_discount_amount' && attr.value !== 'null',
        )

        const {
          merchandise: { id, product, title },
          quantity,
          addons,
        } = currentItemFromCart as CartPageLineItemScopeData['line']

        this.product = { ...product, subtitle: productDetails?.subtitle }
        this.variantNode = product.variants.edges.find(
          (edge) => edge.node.id === id,
        )?.node!
        this.variantTitle = title
        this.inputQuantity = quantity
        this.lineItemImageSrc = this.getLineItemImage(
          this.product?.variants?.edges?.find(
            (edge: any) => edge.node.id === id,
          ).node?.image?.url || this.product.featuredImage?.url,
        )
        this.addons = Array.isArray(addons) ? addons : []

        this.rawData = currentItemFromCart
        this.isSubscriptionItem = isSubscriptionLineItem(this.rawData)
        let variantPrice = this.variantNode.price.amount
        if (!this.isSubscriptionItem && this.isPartialRedemption) {
          variantPrice =
            variantPrice - Number(this.redemptionDiscount.value) / 100
        }
        this.variantPrice = Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: this.variantNode.price.currencyCode,
          //@ts-ignore trailingZeroDisplay works on browser but not nodejs
          trailingZeroDisplay: 'stripIfInteger',
        }).format(Number(variantPrice))
        this.variantCompareAtPrice = this.variantNode.compareAtPrice
          ? formatPrice(Number(this.variantNode.compareAtPrice.amount))
          : ''
        this.subscriptionType = getSubscriptionType(this.rawData)
        this.groupLinesToBags(
          currentItemFromCart,
          cart.lines,
          rawAddons,
          addonGroups,
        )
        this.updateProductFeatures()
      },
    },

    get link() {
      const storedPath = this.rawData.attributes.find(
        (attribute: { key: string; value: string }) =>
          attribute.key === '_product_path',
      )?.value

      return storedPath || `/products/${this.product.handle}`
    },

    get itemPrice() {
      return `${this.variantPrice}${
        this.isSubscriptionItem ? ` ${this.subscriptionType}` : ''
      }`
    },

    get giftItemPrice() {
      return this.isSwellGift ? 'FREE' : `FREE GIFT`
    },

    get lineItemTotalAmount() {
      const {
        quantity,
        cost: { totalAmount },
      } = this.rawData

      const variantCurrency = totalAmount.currencyCode
      const variantTotalAmount = Number(totalAmount.amount)
      const addonsTotalAmount = this.addons.reduce(
        (amount: number, addon: CartAddonItem) => {
          const {
            merchandise: {
              product: { variants },
              id,
            },
          } = addon
          const variantNode = variants.edges.find((edge) => edge.node.id === id)
            ?.node!
          amount +=
            Number(variantNode.price.amount) * addon._addonQuantity * quantity
          return amount
        },
        0,
      )

      return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: variantCurrency,
        //@ts-ignore trailingZeroDisplay works on browser but not nodejs
        trailingZeroDisplay: 'stripIfInteger',
      }).format(variantTotalAmount + addonsTotalAmount)
    },

    getAddonsForGroup(groupId: string) {
      return this.addons.filter(
        (addon: CartAddonItem) => addon._groupId === groupId,
      )
    },

    forceUpdate(line: AdvancedCartItem) {
      const { addons } = line
      this.rawData = line
      this.addons = Array.isArray(addons) ? addons : []
    },

    updateProductFeatures() {
      const features = []

      const displayProperties = JSON.parse(
        getLineItemAttributeByKey(this.rawData, '_display_line_properties') ||
          '[]',
      )
      if (displayProperties.length) {
        features.push(...displayProperties)
      } else {
        if (this.variantTitle !== 'Default Title')
          features.push(this.variantTitle)
      }

      const startDate = getLineItemAttributeByKey(this.rawData, '_start_date')
      const redemptionNumber = getLineItemAttributeByKey(
        this.rawData,
        '_redemption_number',
      )
      if (startDate) {
        if (redemptionNumber) {
          features.push(
            `Scheduled Meal Week: ${dayjs(startDate).format('dddd, MMMM Do')}`,
          )
        } else {
          features.push(`Starting ${dayjs(startDate).format('dddd, MMMM Do')}`)
        }
      }

      const isGiftCard = this.rawData.merchandise.product.isGiftCard
      if (isGiftCard) {
        features.push(
          `To Name: ${getLineItemAttributeByKey(this.rawData, 'to_name')}`,
        )
        features.push(
          `To Email: ${getLineItemAttributeByKey(this.rawData, 'to_email')}`,
        )
        features.push(
          `From Name: ${getLineItemAttributeByKey(this.rawData, 'from_name')}`,
        )
      }
      this.productFeatures = features
    },

    getLineItemImage(url?: string) {
      if (!url) return undefined

      return `${url}&width=800`
    },

    updateInputQuantity(quantity: number) {
      this.inputQuantity = quantity
    },

    transformCartLineUpdateInput(line: CartItem) {
      const updateItem: CartLineInput = {
        attributes: Array.from(line.attributes),
        id: line.id,
        merchandiseId: line.merchandise.id,
        quantity: line.quantity,
      }

      if (line.sellingPlanAllocation)
        updateItem.sellingPlanId = line.sellingPlanAllocation.sellingPlan.id
      return updateItem
    },

    async cartUpdateItem(
      actionType: CartPageLineItemUpdateAction,
      { line }: CartPageLineItemUpdatePayload,
    ) {
      this.isUpdating = true
      if (!line) return console.error('line item not found')

      const lineOriginalQuantity = line.quantity
      let updateQuantityDiff = 1

      const updateItem: CartLineInput = this.transformCartLineUpdateInput(line)

      if (actionType === 'add') {
        updateItem.quantity! += 1

        this.$dispatch(NOTICE_UPDATE_QUANTITY, {
          item: line,
          quantityDifference: updateQuantityDiff,
        })
      }

      if (actionType === 'remove') {
        updateItem.quantity! -= 1
        updateQuantityDiff = -1

        this.$dispatch(NOTICE_UPDATE_QUANTITY, {
          item: line,
          quantityDifference: updateQuantityDiff,
        })
      }

      if (actionType === 'set') {
        const newQuantity = Number(this.inputQuantity)

        updateItem.quantity = newQuantity > 99 ? 99 : newQuantity
        updateQuantityDiff = updateItem.quantity - lineOriginalQuantity

        this.$dispatch(NOTICE_UPDATE_QUANTITY, {
          item: line,
          quantityDifference: updateQuantityDiff,
        })
      }

      let addonItems: CartLineInput[] = []
      if (this.addons.length > 0) {
        for (const addon of this.addons) {
          const addonQuantity = addon._addonQuantity
          const addonUpdateQuantity =
            addon.quantity + updateQuantityDiff * addonQuantity
          addonItems.push({ id: addon.id, quantity: addonUpdateQuantity })
        }
      }

      const newCart = await updateItems([updateItem, ...addonItems])

      if (newCart) {
        const giftItemOnly =
          newCart.lines.length === 1 &&
          newCart.lines[0].attributes.find(
            (attribute) => attribute.key === '_swell_redemption_id',
          )

        if (giftItemOnly) {
          const updatedCart = await updateItems([
            { id: newCart.lines[0].merchandise.id, quantity: 0 },
          ])
          if (updatedCart) {
            this.$dispatch(CART_UPDATED, updatedCart)
          } else {
            this.$dispatch(CART_UPDATED, newCart)
          }
        }
      }

      this.isUpdating = false
    },
  }))
})
